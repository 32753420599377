import React, { useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import Image from '../Image/Image';
import Icon from '../Icon/Icon';
import { Constants } from '../../adapters/helpers/Constants';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import { addReminder } from '../../adapters/helpers/Utils';

const CalendarReminder = (props) => {
  const { document: doc } = props;

  const header = doc?.fields?.header;
  const calendarImage = doc?.fields?.calendarImage;
  const title = doc?.fields?.title;
  const description = doc?.fields?.description;
  const ctaLabel = doc?.fields?.calendarCtaLabel;
  const reminderList = doc?.fields?.reminderList;

  const [isCalendarDropdownOpen, setIsCalendarDropdownOpen] = useState(false);

  const handleCalendarDropdown = () => {
    setIsCalendarDropdownOpen(!isCalendarDropdownOpen);
  };

  return (
    <section className='reminder-calendar'>
      <h2
        className='reminder-header'
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(header) }}
      />
      <div className='reminder-content-container'>
        <div className='reminder-image-container'>
          <Image image={calendarImage} />
        </div>
        <div className='reminder-text-container'>
          <div
            className='reminder-title'
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
          />
          <div
            className='reminder-description'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description),
            }}
          />
          <button
            className='reminder-cta'
            onClick={() => handleCalendarDropdown()}
          >
            {ctaLabel}
          </button>
          {isCalendarDropdownOpen && (
            <div className='remainder-calendar-selector'>
              <button
                className='event_close_window reminder-close-button'
                onClick={() => handleCalendarDropdown()}
              >
                <Icon name={Constants.close} color='#FFFFFF' />
              </button>
              {reminderList.map((option) => {
                return (
                  <div
                    className={`${option?.fields?.ctaCustomEventClass} calender-content`}
                    onClick={() => addReminder(doc?.fields, option?.fields)}
                    data-action-detail={`${option?.fields?.type} ${AnalyticsConstants.calender}`}
                  >
                    <span class='calender-img'>
                      <Image image={option?.fields?.icon} />
                    </span>
                    <span class='calender-option-list'>
                      {option?.fields?.type}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CalendarReminder;
