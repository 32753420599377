import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { mediaQueryHOC } from '../Mixture/View/exportComponent';
import Image from '../Image/Image';
import { useScrollBlock } from '../../adapters/helpers/Hooks';
import 'swiper/css/bundle';

const CardPopupBlock = (props) => {
  const { document: doc, isMedium } = props;

  const header = doc?.fields?.header;
  const subHeader = doc?.fields?.subHeader;
  const popUpCards = doc?.fields?.popUpCards;
  const ctaImage = doc?.fields?.ctaImage;
  const ctaTitle = doc?.fields?.ctaTitle;
  const ctaLabel = doc?.fields?.ctaLabel;
  const ctaLink = doc?.fields?.ctaLink;
  const swiperLeftArrow = doc?.fields?.swiperLeftArrow?.fields?.file?.url;
  const swiperRightArrow = doc?.fields?.swiperRightArrow?.fields?.file?.url;
  const arrowEnabled = doc?.fields?.arrowsEnabled;

  const [popupStates, setPopupStates] = useState({});
  const [blockScroll, allowScroll] = useScrollBlock();
  const swiperRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const nextButton = document.querySelector('.swiper-button-next');
      const prevButton = document.querySelector('.swiper-button-prev');

      if (nextButton) {
        nextButton.style.background = `url('https:${swiperRightArrow}')`;
      }

      if (prevButton) {
        prevButton.style.background = `url('https:${swiperLeftArrow}')`;
      }
    }, 1000);
  }, []);

  const handlePopup = (id) => {
    setPopupStates((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const renderDesktopCards = () => {
    return (
      <div className='popup-card-container'>
        {popUpCards &&
          popUpCards.map((oneCard, index) => {
            return (
              <div className='one-card'>
                <div className='one-card-wrapper'>
                  <div className='one-card-image-container'>
                    <Image image={oneCard?.fields?.backgroundImage} />
                  </div>
                  <div className='one-card-text-container'>
                    <div
                      className='one-card-title'
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(oneCard?.fields?.title),
                      }}
                    />
                    <div
                      className='one-card-sub-title'
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(oneCard?.fields?.subTitle),
                      }}
                    />
                    <div
                      className='one-card-description'
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          oneCard?.fields?.description
                        ),
                      }}
                    />
                    {oneCard?.fields?.ctaLabel && (
                      <button
                        className='one-card-cta'
                        onClick={() => {
                          handlePopup(index);
                          blockScroll();
                        }}
                      >
                        {oneCard?.fields?.ctaLabel}
                      </button>
                    )}
                  </div>
                </div>
                {popupStates[index] && (
                  <div className='one-card-popup-wrapper'>
                    <Image image={oneCard?.fields?.popUpImage}>
                      <div
                        className='one-card-popup-close'
                        onClick={() => {
                          handlePopup(index);
                          allowScroll();
                        }}
                      />
                      <a
                        className='one-card-popup-buy'
                        href={`${oneCard?.fields?.buyNowLink}`}
                        target='_blank'
                        rel='noreferrer noopener'
                      />
                    </Image>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  const renderMobileCards = () => {
    return (
      <>
        <Swiper
          ref={swiperRef}
          modules={[Pagination, Navigation]}
          draggable
          centeredSlides
          loop={true}
          pagination={{ clickable: true }}
          navigation={arrowEnabled ? true : false}
        >
          {popUpCards &&
            popUpCards.map((oneCard, index) => {
              return (
                <SwiperSlide>
                  <div className='one-card-m'>
                    <div className='one-card-wrapper'>
                      <div className='one-card-image-container'>
                        <Image image={oneCard?.fields?.mobileBackgroundImage} />
                      </div>
                      <div className='one-card-text-container'>
                        <div
                          className='one-card-title'
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(oneCard?.fields?.title),
                          }}
                        />
                        <div
                          className='one-card-sub-title'
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              oneCard?.fields?.subTitle
                            ),
                          }}
                        />
                        <div
                          className='one-card-description'
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              oneCard?.fields?.description
                            ),
                          }}
                        />
                        {oneCard?.fields?.ctaLabel && (
                          <button
                            className='one-card-cta'
                            onClick={() => {
                              handlePopup(index);
                              blockScroll();
                            }}
                          >
                            {oneCard?.fields?.ctaLabel}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
        {popUpCards &&
          popUpCards.map((oneCard, index) => {
            if (popupStates[index]) {
              return (
                <div
                  key={`popup-${index}`}
                  className='one-card-popup-wrapper-m'
                >
                  <Image image={oneCard.fields.popUpImageMobile} />
                  <div
                    className='one-card-popup-close'
                    onClick={() => {
                      handlePopup(index);
                      allowScroll();
                    }}
                  />
                  <a
                    className='one-card-popup-buy'
                    href={`${oneCard.fields.buyNowLink}`}
                    target='_blank'
                    rel='noreferrer noopener'
                  />
                </div>
              );
            }
            return null;
          })}
      </>
    );
  };

  return (
    <section className='card-popup'>
      <h2
        className='card-popup-header'
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(header) }}
      />
      <h3
        className='card-popup-sub-header'
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subHeader) }}
      />
      {!isMedium && renderDesktopCards()}
      {isMedium && renderMobileCards()}
      {ctaImage && <Image className={'card-popup-cta-image'} image={ctaImage} />}
      {ctaTitle && (
        <div
          className='card-popup-cta-title'
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ctaTitle) }}
        />
      )}
      {ctaLabel && (
        <a
          className='card-popup-cta'
          href={`${ctaLink}`}
          target='_blank'
          rel='noreferrer noopener'
        >
          <span>{ctaLabel}</span>
        </a>
      )}
    </section>
  );
};

export default mediaQueryHOC(CardPopupBlock);
